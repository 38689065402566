<template>
  <div>
    <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
      <div style="display: flex">
        <el-alert
            type="warning"
            :title="sysTitle"
            :closable="false"
            show-icon
            style="flex-direction: row"
        />
        <a href="/#/setUp/systemSetup"
           style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
      </div>
    </el-card>
    <el-row :gutter="15">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="mb15">
        <el-card shadow="hover" style="background: linear-gradient(to right, #fe9365, #feb798);">
          <div class="grid-content bg-purple">
            <a href="/#/relay">
              <div class="block">
                <div class="amount">
                  <div class="quantity">{{ trunk }}</div>
                  <div class="name">{{ $t('中继设置') }}</div>
                </div>
                <el-avatar :size="60" class="icon">
                  <i class="el-icon-s-operation"></i>
                </el-avatar>
              </div>
            </a>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="mb15">
        <el-card shadow="hover" style="background: linear-gradient(to right, #0ac282, #0df3a3)">
          <div class="grid-content bg-purple">
            <a href="/#/extension">
              <div class="block">
                <div class="amount">
                  <div class="quantity">{{ station }}</div>
                  <div class="name">{{ $t('分机设置') }}</div>
                </div>
                <el-avatar :size="60" class="icon">
                  <i class="el-icon-phone"></i>
                </el-avatar>
              </div>
            </a>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="mb15">
        <el-card shadow="hover" style="background: linear-gradient(to right, #fe5d70, #fe909d)">
          <div class="grid-content bg-purple">
            <a href="/#/control/exhale">
              <div class="block">
                <div class="amount">
                  <div class="quantity">{{ routeout }}</div>
                  <div class="name">{{ $t('呼出路由') }}</div>
                </div>
                <el-avatar :size="60" class="icon">
                  <i class="fa fa-arrow-up"></i>
                </el-avatar>
              </div>
            </a>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="mb15">
        <el-card shadow="hover" style="background: linear-gradient(to right, #01a9ac, #01dbdf);">
          <div class="grid-content bg-purple">
            <a href="/#/control/incomingCall">
              <div class="block">
                <div class="amount">
                  <div class="quantity">{{ routein }}</div>
                  <div class="name">{{ $t('呼入路由') }}</div>
                </div>
                <el-avatar :size="60" class="icon">
                  <i class="fa fa-arrow-down"></i>
                </el-avatar>
              </div>
            </a>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="16" class="mb15">
        <el-card shadow="hover" :header="$t('今日通话')">
          <div style="height: 300px;width:100%" ref="homeLaboratoryRef"></div>
          <el-row :gutter="10" style="height:120px;">
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-for="(gross , m) in totalData" :key="m">
              <div class="grid-content bg-purple statistics">
                <h1>{{ gross.titleNum }}</h1>
                <span>{{ $t(gross.title) }}</span>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="8" class="mb15">
        <el-card shadow="hover" :header="$t('系统监控')">
          <div class="u-cpu-content">
            <div style="width:100%;height:130px;" ref="cpuRef"></div>
            <div style="width:100%;height:130px;margin-top:10px;" ref="memRef"></div>
            <div style="width:100%;height:130px;margin-top:10px;" ref="diskRef"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="15">-->
    <!--      &lt;!&ndash; <el-card shadow="hover"> &ndash;&gt;-->
    <!--      <el-col :span="24">-->
    <!--        <div class="grid-content bg-purple-dark incident">-->
    <!--          <el-link :underline="false">{{ $t('事件日志') }}</el-link>-->
    <!--          &lt;!&ndash; <el-button class="empty" type="primary" round="true" size="mini" icon="fa fa-refresh">{{$t('清空')}}</el-button> &ndash;&gt;-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--      <el-col :span="24" style="padding:20px 10px;">-->
    <!--        <el-alert :title="$t('成功')" type="success" :closable="false" show-icon class="hint"></el-alert>-->
    <!--        <el-alert :title="$t('提示')" type="info" :closable="false" show-icon class="hint"></el-alert>-->
    <!--        <el-alert :title="$t('警告')" type="warning" :closable="false" show-icon class="hint"></el-alert>-->
    <!--        <el-alert :title="$t('错误')" type="error" :closable="false" show-icon class="hint"></el-alert>-->
    <!--      </el-col>-->
    <!--      <el-table :data="eventLog" style="width: 100%">-->
    <!--        <el-table-column :label="$t('状态')">-->
    <!--          <template #default="scope">-->
    <!--            <i :class="scope.row.icon"></i>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column prop="date" :label="$t('日期')"></el-table-column>-->
    <!--        <el-table-column prop="name" :label="$t('姓名')"></el-table-column>-->
    <!--        <el-table-column prop="address" :label="$t('地址')"></el-table-column>-->
    <!--        <el-table-column fixed="right" :label="$t('操作')">-->
    <!--          <template #default="scope">-->
    <!--            <el-button @click.prevent="deleteRow(scope.$index, eventLog)" type="text" size="small">-->
    <!--              {{ $t('移除') }}-->
    <!--            </el-button>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--      </el-table>-->
    <!--      &lt;!&ndash; </el-card> &ndash;&gt;-->
    <!--    </el-row>-->
  </div>
</template>
<script>
import {
  onMounted,
  getCurrentInstance,
  reactive,
  toRefs,
  nextTick,
} from 'vue'
import {useI18n} from "vue-i18n";
import * as echarts from 'echarts';
import {
  topCardItemList,
  eventLog,
} from './homeData';
import {ElNotification} from "element-plus";
import {
  getBase,
  getChart,
  getCpu,
} from '@/http/home.api.js'
import router from "@/router";

function baseinfo(state, fn) {
  let params = {}
  getBase(params).then(res => {
    if (res.code == 200) {
      state.station  = res.data.station;
      state.trunk    = res.data.trunk;
      state.routein  = res.data.routeIn;
      state.routeout = res.data.routeOut;
      state.sysTitle = res.tag;
      if (res.tag) {
        router.push('/setUp/systemSetup')
      }
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  }).catch(err => {
    console.log(err, 'err');
  })

  getChart(params).then(res => {
    if (res.code == 200) {
      state.data_in                  = res.data.data_in;
      state.data_in_t                = res.data.data_in_t;
      state.data_out                 = res.data.data_out;
      state.data_out_t               = res.data.data_out_t;
      state.totalData.out_t.titleNum = res.data.count_out_t;
      state.totalData.out_r.titleNum = res.data.count_out_r;
      state.totalData.out.titleNum   = res.data.count_out;
      state.totalData.in.titleNum    = res.data.count_in;
      state.totalData.in_t.titleNum  = res.data.count_in_t;
      state.totalData.in_r.titleNum  = res.data.count_in_r;
      if (fn) {
        fn();
      }
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  }).catch(err => {
    console.log(err, 'err');
  })
}


export default {
  name: 'App',
  setup() {
    const {proxy} = getCurrentInstance();
    const {t}     = useI18n()
    const state   = reactive({
      sysTitle: "",
      myCharts: [],
      topCardItemList,
      totalData: [],
      eventLog,
      station: 0,
      trunk: 0,
      routein: 0,
      routeout: 0,
      data_in: [],
      data_in_t: [],
      data_out: [],
      data_out_t: [],
      mon_cpu: 0,
      mon_mem: 0,
      mon_disk: 0,
    });

    state.totalData = {
      in: {
        title: t('呼入'),
        titleNum: '0',
      },
      in_t: {
        title: t('呼入接听'),
        titleNum: '0',
      },
      in_r: {
        title: t('呼入接听比率'),
        titleNum: '0%',
      },
      out: {
        title: t('呼出'),
        titleNum: '0',
      },
      out_t: {
        title: t('呼出接听'),
        titleNum: '20',
      },
      out_r: {
        title: t('呼出接听比率'),
        titleNum: '0%',
      },
    };
    // 页面加载时
    onMounted(() => {
      baseinfo(state, function () {
        initHomeLaboratory();
      });
      initHomeLaboratory();
      initEchartsResize();
      initMon(function () {
        monChart();
      });
      monChart();
    });

    const initMon = (fn) => {

      getCpu({}).then(res => {
        if (res.code == 200) {
          state.mon_cpu  = res.data.cpu;
          state.mon_mem  = res.data.mem;
          state.mon_disk = res.data.disk;
          if (fn) {
            fn();
          }
        } else {
          ElNotification({
            title: '失败',
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      });
    }

    const initHomeLaboratory = () => {
      const myChart = echarts.init(proxy.$refs.homeLaboratoryRef);
      const option  = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [t('呼入'), t('呼入接听'), t('呼出'), t('呼出接听')]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: 1,
          x: 'right',
          y: 'top',
          feature: {
            saveAsImage: {title: t('保存截图'), type: 'png'}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00',
            '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00',
            '23:00', '24:00'
          ]
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: t('呼入'),
          type: 'line',
          stack: '总量',
          data: [...state.data_in]
        },
          {
            name: t('呼入接听'),
            type: 'line',
            stack: '总量',
            data: [...state.data_in_t]
          },
          {
            name: t('呼出'),
            type: 'line',
            stack: '总量',
            data: [...state.data_out]
          },
          {
            name: t('呼出接听'),
            type: 'line',
            stack: '总量',
            data: [...state.data_out_t]
          }
        ]
      };
      myChart.setOption(option);
      state.myCharts.push(myChart);
    };

    // 批量设置 echarts resize
    const initEchartsResizeFun = () => {
      nextTick(() => {
        for (let i = 0; i < state.myCharts.length; i++) {
          state.myCharts[i].resize();
        }
      });
    };
    // 批量设置 echarts resize
    const initEchartsResize    = () => {
      window.addEventListener('resize', initEchartsResizeFun);
    };

    const deleteRow = (index, rows) => {
      rows.splice(index, 1)
    };

    const monChart = () => {
      // 将data赋值
      const cpuEchart  = echarts.init(proxy.$refs.cpuRef);
      const memEchart  = echarts.init(proxy.$refs.memRef);
      const diskEchart = echarts.init(proxy.$refs.diskRef);

      // 饼图的公共系列属性
      let cpuOption  = {
        backgroundColor: '#fff',
        color: ['#FE5D70FF', '#cacaca'],
        title: {
          text: t('CPU使用率'),
          left: '5%',
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 16,
          }
        },
        series: [{
          name: '来源',
          type: 'pie',
          radius: ['60%', '75%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
              textStyle: {
                color: '#FE5D70FF',
                fontSize: 14,
                fontWeight: 'bold'
              },
              formatter: '{c}%\r\n{b}'
            }
          },
          data: [{
            value: state.mon_cpu,
            name: 'CPU',
            label: {
              normal: {
                show: true
              }
            }
          },
            {
              value: 100 - 30,
              name: ''
            }
          ]
        }]
      };
      // 饼图的公共系列属性
      let memOption  = {
        backgroundColor: '#fff',
        color: ['#01A9ACFF', '#cacaca'],
        title: {
          text: t('内存使用率'),
          left: '5%',
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 16,
          }
        },
        series: [{
          name: '来源',
          type: 'pie',
          radius: ['60%', '75%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
              textStyle: {
                color: '#01A9ACFF',
                fontSize: 14,
                fontWeight: 'bold'
              },
              formatter: '{c}%\r\n{b}'
            }
          },
          data: [{
            value: state.mon_mem,
            name: t('内存'),
            label: {
              normal: {
                show: true
              }
            }
          },
            {
              value: 100 - 30,
              name: ''
            }
          ]
        }]
      };
      // 饼图的公共系列属性
      let diskOption = {
        backgroundColor: '#fff',
        color: ['#0AC282FF', '#cacaca'],
        title: {
          text: t('磁盘使用率'),
          left: '5%',
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 16,
          }
        },
        series: [{
          name: '来源',
          type: 'pie',
          radius: ['60%', '75%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
              textStyle: {
                color: '#0AC282FF',
                fontSize: 14,
                fontWeight: 'bold'
              },
              formatter: '{c}%\r\n{b}'
            }
          },
          data: [{
            value: state.mon_disk,
            name: t('磁盘'),
            label: {
              normal: {
                show: true
              }
            }
          },
            {
              value: 100 - 30,
              name: ''
            }
          ]
        }]
      };
      cpuEchart.setOption(cpuOption);
      memEchart.setOption(memOption);
      diskEchart.setOption(diskOption);
    }

    return {
      ...toRefs(state),
      deleteRow,
      initMon,
    }
  }
}
</script>
<style scoped>
@import "../../theme/home/index.scss";

.u-cpu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 420px;
}

.el-card :deep(.el-card__header) {
  font-size: 15px;
}
</style>
