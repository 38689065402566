import request from './index.js'

//获取中继列表
export function getBase(data = {}) {
    return request({
        url: "/api/index/base",
        method: "GET",
        params: data
    })
}

export function getChart(data = {}) {
    return request({
        url: "/api/cdr/today",
        method: "GET",
        params: data
    })
}

export function getCpu(data = {}) {
    return request({
        url: "/api/system/cpu/info",
        method: "GET",
        params: data
    })
}
